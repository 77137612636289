import React, { useEffect, useMemo, useRef, useState } from 'react';

// @ts-ignore-next-line no types
import matrix from 'calendar-matrix';
import { EmbedTemplateParams } from '../..';
import axios from 'axios';
import { Popover } from 'react-tiny-popover';
import { numpad } from '../../../../../../shared/lib/time';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { debounce } from 'debounce-promise-with-cancel';

const EXPANDED_ROW_COL_SIZE = 3;
const ANIMATION_DURATION = 250;

interface AppPropsBase {
  config: EmbedTemplateParams['config'];
}

interface ExportApiEvent {
  id: string;
  name: string;
  venue: string;
  timeString: string;
  image: string;
  description: string;
  callToAction: string;
  attributes: string[];
  highlights: string[];
}

interface ExportApiResponse {
  lineup: Array<{
    events: Array<ExportApiEvent>;
  }>;
}

type AppProps = AppPropsBase & React.HTMLProps<HTMLDivElement>;

function getDefaultGridSize(size: number, date?: Date) {
  const gridSize = Array(size).fill(1);
  if (date) gridSize[date.getDay()] = 1;
  return gridSize;
}

function App(props: AppProps) {
  const { config, ...rest } = props;
  const currentDate = new Date();

  const [monthDate, setMonthDate] = useState(currentDate);
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);

  const dayMatrix: number[][] = matrix(monthDate);

  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState<ExportApiEvent[][]>([[]]);
  const [popoverStates, setPopoverStates] = useState<{ [key: string]: boolean }>({});
  const [gridColSizes, setGridColSizes] = useState(getDefaultGridSize(7, currentDate));
  const [gridRowSizes, setGridRowSizes] = useState(
    getDefaultGridSize(dayMatrix.length, currentDate)
  );
  const [popoverOutDelay, setPopoverOutDelay] = useState<NodeJS.Timeout>();
  const [monthDelay, setMonthDelay] = useState<NodeJS.Timeout>();

  const [gridWidth, setGridWidth] = useState(0);
  const [_, setGridHeight] = useState(0);
  const [popoverActive, setPopoverActive] = useState(false);
  const [hoverCell, setHoverCell] = useState(-99);
  const [focusCell, setFocusCell] = useState(currentDate.getDate());
  const [scrollCellReset, setScrollCellReset] = useState(-99);
  const [attributes, setAttributes] = useState<string[]>([]);
  const [selectedAttribute, setSelectedAttribute] = useState<string | undefined>();

  const gridDiv = useRef<HTMLDivElement>(null);
  const stickyDiv = useRef<HTMLDivElement>(null);
  const daysDiv = useRef<HTMLDivElement>(null);

  const isMobile = gridWidth < 620;

  useMemo(() => {
    if (isMobile) {
      togglePopover(undefined, false);
    }
  }, [isMobile]);

  useEffect(() => {
    fetchEvents(monthDate).catch(console.error);

    return () => {
      if (popoverOutDelay) {
        clearTimeout(popoverOutDelay);
      }
      if (monthDelay) {
        clearTimeout(monthDelay);
      }

      resetState();
    };
  }, []);

  useEffect(() => {
    const states = makePopoverStates();
    setPopoverStates(states);
  }, [events]);

  useEffect(() => {
    const popoverActive = Object.values(popoverStates).includes(true);

    if (popoverActive) {
      if (popoverOutDelay) {
        clearTimeout(popoverOutDelay);
      }

      setPopoverActive(true);
    } else {
      const timeout = setTimeout(() => {
        setPopoverActive(false);
      }, ANIMATION_DURATION);

      if (popoverOutDelay) {
        clearTimeout(popoverOutDelay);
      }

      setPopoverOutDelay(timeout);
    }
  }, [popoverStates]);

  useEffect(() => {
    if (!gridDiv.current) return;

    const resizeObserver = new ResizeObserver(() => {
      setGridWidth(gridDiv.current?.offsetWidth ?? 0);
      setGridHeight(gridDiv.current?.offsetHeight ?? 0);
    });

    resizeObserver.observe(gridDiv.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [gridDiv.current]);

  useEffect(() => {
    resetState();
  }, [monthDate]);

  useEffect(() => {
    updateSelectedDate();
  }, [focusCell]);

  function updateGrid(d: number | undefined) {
    let x = -1;
    let y = -1;

    const newGridColSize = getDefaultGridSize(7);
    const newGridRowSize = getDefaultGridSize(dayMatrix.length);

    if (d !== undefined && d > 0) {
      dayMatrix.forEach((wk, i) => {
        if (x >= 0) return;

        x = wk.findIndex(e => d === e);

        if (x >= 0) {
          y = i;
        }
      });

      newGridColSize[x] = EXPANDED_ROW_COL_SIZE;
      newGridRowSize[y] = EXPANDED_ROW_COL_SIZE;
    }

    setGridColSizes(newGridColSize);
    setGridRowSizes(newGridRowSize);
  }

  function updateSelectedDate() {
    if (focusCell < 0) return;

    const monthIso = monthDate.toISOString();
    const monthTokens = monthIso.split('-');
    monthTokens[monthTokens.length - 1] = numpad(focusCell);

    const date = new Date(monthTokens.join('-'));
    const timezoneOffset = date.getTimezoneOffset() * 60000;
    const newDate = new Date(date.valueOf() + timezoneOffset);

    setSelectedDate(newDate);
  }

  function resetState() {
    setPopoverStates({});
    setGridColSizes(getDefaultGridSize(7, currentDate));
    setGridRowSizes(getDefaultGridSize(dayMatrix.length, currentDate));

    setHoverCell(-99);
    setFocusCell(
      currentDate.getMonth() === monthDate.getMonth() &&
        currentDate.getFullYear() == monthDate.getFullYear()
        ? currentDate.getDate()
        : 1
    );
    setPopoverActive(false);
    updateSelectedDate();

    if (popoverOutDelay) {
      clearTimeout(popoverOutDelay);
    }
  }

  function makePopoverStates() {
    const states: { [key: string]: boolean } = {};

    events.flat().forEach(e => {
      states[e.id] = false;
    });

    return states;
  }

  async function fetchEvents(date: Date) {
    setLoading(true);

    try {
      const response = await axios.get<ExportApiResponse>(config.exportApiUrl, {
        params: { date: new Date(date).toISOString().slice(0, 8) + '01', days: getNumOfDays() },
        headers: {
          Authorization: `Bearer ${config.exportApiToken}`,
        },
      });
      const data = response.data.lineup.map(l => l.events);
      setEvents(data);
      const attributeSet = new Set<string>();
      data.flat().forEach(e => {
        e.attributes.forEach(a => attributeSet.add(a));
        e.highlights.forEach(h => attributeSet.add(h));
      });
      setAttributes(Array.from(attributeSet));
    } catch (error) {
      setEvents([[]]);
      throw error;
    } finally {
      setLoading(false);
    }
  }

  async function changeMonth(
    direction: 1 | -1,
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    if (e) {
      e.stopPropagation();
    }

    const copyDate = new Date(monthDate);
    copyDate.setMonth(copyDate.getMonth() + direction, 1);

    setEvents([]);
    setLoading(true);
    setMonthDate(copyDate);

    if (monthDelay) {
      clearTimeout(monthDelay);
    }

    const timeout = setTimeout(() => {
      fetchEvents(copyDate).catch(console.error);
    }, ANIMATION_DURATION * 2);

    setMonthDelay(timeout);

    handleDateClick();
  }

  function handleDateClick() {
    if (!daysDiv.current || !stickyDiv.current) return;

    const divToScroll =
      (config.scrollDiv ? document.getElementById(config.scrollDiv) : undefined) || window;

    divToScroll.scrollTo({
      top: daysDiv.current.offsetTop - stickyDiv.current.getBoundingClientRect().height,
      behavior: 'smooth',
    });
  }

  async function handleCellMouseOver(i: number, d: number) {
    if (hoverCell === d || popoverActive || loading) return;

    setHoverCell(d < 0 ? -99 : d);

    if (gridDiv.current && scrollCellReset != i) {
      gridDiv.current.querySelectorAll(`.go-cell-${scrollCellReset}`).forEach(div => {
        div.children[0].scrollTo(0, 0);
      });
    }

    setScrollCellReset(i);
    updateGrid(d);
  }

  const handleCellMouseOverDebounced = debounce(handleCellMouseOver, ANIMATION_DURATION / 2);

  function handleGridMouseOut(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    handleCellMouseOverDebounced.cancel();
    const from = e.relatedTarget as HTMLDivElement;

    if (!gridDiv || !gridDiv.current || popoverActive) return;

    if (!gridDiv.current.contains(from)) {
      setHoverCell(-99);
      updateGrid(undefined);

      gridDiv.current.querySelectorAll(`.go-cell-container`).forEach(div => {
        div.scrollTo(0, 0);
      });
    }
  }

  function handleCellClick(d: number, e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (!(e.target as HTMLElement).className.includes('go-event')) {
      togglePopover(undefined, false);
    }
    setFocusCell(d);
    updateGrid(d);
  }

  function getNumOfDays() {
    return Math.max(...dayMatrix.flat());
  }

  function togglePopover(
    eId: string | undefined,
    state: boolean,
    clickEvt?: React.MouseEvent<HTMLDivElement, MouseEvent>,
    force?: boolean
  ) {
    if (clickEvt) {
      clickEvt.stopPropagation();
    }

    const states = makePopoverStates();

    if (eId && (force || !popoverActive)) {
      states[eId] = state;
    }

    setPopoverStates(states);
  }

  function filterEvents(events: ExportApiEvent[], filter: string | undefined) {
    return events.filter(
      e => !filter || e.attributes.includes(filter) || e.highlights.includes(filter)
    );
  }

  function isExpanded(i: number) {
    return gridColSizes[i % 7] != 1;
  }

  function isActive(i: number, d: number) {
    return isExpanded(i) && (hoverCell === d || focusCell === d);
  }

  function isCurrentDay(d: number) {
    return (
      currentDate.getMonth() === monthDate.getMonth() &&
      currentDate.getFullYear() === monthDate.getFullYear() &&
      d === currentDate.getDate()
    );
  }

  function isFirstRow(d: number) {
    return dayMatrix[0].findIndex(d2 => d2 === d) > -1;
  }

  function isFirstCol(d: number) {
    return (
      dayMatrix
        .map(wk => wk[0])
        .flat()
        .findIndex(d2 => d2 === d) > -1
    );
  }

  const gridDirty = isMobile ? false : gridColSizes.findIndex(e => e != 1) > -1;
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  return (
    <div id="go-app" style={{ minHeight: isMobile ? '100vh' : undefined }} {...rest}>
      <div
        ref={stickyDiv}
        className={`${
          isMobile ? 'sticky bg-background border-bottom-1 border-table' : ''
        } px-3 pt-3 pb-2 flex items-center go-heading`}
        style={{ zIndex: 2, top: 0 }}
      >
        {selectedDate && (
          <div
            className={`transition-all whitespace-nowrap text-accent font-bold mr-2${
              isMobile ? ' underline' : ''
            } go-current-date`}
            style={{ opacity: loading ? 0.5 : 1 }}
            onClick={handleDateClick}
          >
            {selectedDate.toLocaleDateString('default', {
              weekday: 'short',
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
          </div>
        )}
        <select
          value={selectedAttribute}
          onChange={e =>
            setSelectedAttribute(e.target.value === 'none' ? undefined : e.target.value)
          }
          className={`text-accent${
            isMobile ? ' grow' : ' w-64'
          } truncate px-2 py-1 mr-2 border rounded border-accent whitespace-nowrap min-w-0 go-attribute-select`}
        >
          <option value="none">Filter: All</option>
          {attributes.map(a => (
            <option key={a} value={a}>
              {a}
            </option>
          ))}
        </select>
        <div className="ml-auto whitespace-nowrap go-actions">
          <button
            className="text-accent p-2 border border-right-0 rounded-left border-accent go-button go-prev-month"
            onClick={e => changeMonth(-1, e)}
          >
            <svg width="1rem" height="1rem" viewBox="0 0 24 24">
              <path
                d="M20 12H4M4 12L10 6M4 12L10 18"
                stroke={config.accentColor}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          <button
            className="text-accent p-2 border border-left-0 rounded-right border-accent go-button go-next-month"
            onClick={e => changeMonth(1, e)}
          >
            <svg width="1rem" height="1rem" viewBox="0 0 24 24">
              <path
                d="M4 12H20M20 12L14 6M20 12L14 18"
                stroke={config.accentColor}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
      <div
        ref={daysDiv}
        className={`py-2 grid transition-all${loading ? ' opacity-50' : ''} go-day-headings`}
        style={{
          gridTemplateColumns: (isMobile ? getDefaultGridSize(7) : gridColSizes)
            .map(x => `minmax(0,${x}fr)`)
            .join(' '),
        }}
      >
        {days.map((d, i) => (
          <div
            key={d}
            className={`text-center text-secondary${
              !isMobile && isExpanded(i) ? ' text-accent' : ''
            } go-day go-day-${i}`}
          >
            {d}
          </div>
        ))}
      </div>
      <div
        ref={gridDiv}
        className={`grid transition-all${loading ? ' opacity-50' : ''} go-grid`}
        style={{
          height: (dayMatrix.length * gridWidth) / 7,
          cursor: isMobile ? 'pointer' : undefined,
          gridTemplateColumns: (isMobile ? getDefaultGridSize(7) : gridColSizes)
            .map(x => `minmax(0,${x}fr)`)
            .join(' '),
          gridTemplateRows: (isMobile ? getDefaultGridSize(dayMatrix.length) : gridRowSizes)
            .map(x => `minmax(0,${x}fr)`)
            .join(' '),
        }}
        onMouseOut={handleGridMouseOut}
      >
        {dayMatrix.flat().map((d, i) => (
          <div
            key={d}
            className={`relative w-full transition-all${
              isFirstRow(d)
                ? ''
                : isCurrentDay(d) || d === focusCell || d === hoverCell
                ? ' border-top-2'
                : ' border-top-1'
            }${
              isFirstCol(d)
                ? ''
                : isCurrentDay(d) || d === focusCell || d === hoverCell
                ? ' border-left-2'
                : ' border-left-1'
            }${
              d === focusCell
                ? ' border-active'
                : d === hoverCell
                ? ' border-accent'
                : isCurrentDay(d)
                ? ' border-emphasis'
                : ' border-table'
            }${gridDirty ? (isActive(i, d) ? '' : ' opacity-50') : ''} go-cell go-cell-${i}${
              isCurrentDay(d) ? ' active' : ''
            }`}
          >
            <div
              className="full go-cell-container"
              style={{
                opacity: isActive(i, d) ? 1 : !gridDirty ? 1 : 0.5,
                overflowY: isActive(i, d) ? 'auto' : !gridDirty ? 'auto' : 'hidden',
              }}
              onMouseOver={() => handleCellMouseOverDebounced(i, d)}
              onClick={e => handleCellClick(d, e)}
            >
              {isMobile && filterEvents(events[d - 1] || [], selectedAttribute).length > 0 && (
                <div
                  className="bg-emphasis go-cell-marker"
                  style={{
                    position: 'absolute',
                    bottom: 5,
                    right: 5,
                    width: 10,
                    height: 10,
                    borderRadius: 999,
                  }}
                ></div>
              )}
              <div
                className={`pl-1 pt-1 pr-0 font-bold${
                  focusCell === d
                    ? ' text-active'
                    : hoverCell === d
                    ? ' text-accent'
                    : isCurrentDay(d)
                    ? ' text-emphasis'
                    : d < 0
                    ? ' text-disabled'
                    : ' text-secondary'
                } go-day`}
              >
                {Math.abs(d)}
              </div>
              <div className={`${d < 1 ? ' hidden' : ''} go-events`}>
                {!isMobile &&
                  filterEvents(events[d - 1] || [], selectedAttribute)
                    .slice(0, isActive(i, d) ? undefined : 5)
                    .map(evt => (
                      // @ts-ignore
                      <Popover
                        key={evt.id}
                        boundaryElement={gridDiv.current || undefined}
                        parentElement={gridDiv.current || undefined}
                        isOpen={popoverStates[evt.id]}
                        positions={['right', 'left', 'top', 'bottom']} // preferred positions by priority
                        containerStyle={{ zIndex: '3' }}
                        content={
                          <PopoverContent
                            event={evt}
                            config={config}
                            togglePopover={togglePopover}
                            imgClassName="-mx-3"
                            className={`w-80 bg-white p-3 border border-table shadow-lg go-event-popover go-event-popover-${i}`}
                          />
                        }
                      >
                        <div
                          className={`cursor-pointer py-1 pl-1 pr-0 flex${
                            popoverStates[evt.id] ? ' bg-highlight' : ''
                          } go-event go-event-${i}`}
                          onClick={() =>
                            togglePopover(evt.id, !popoverStates[evt.id], undefined, isActive(i, d))
                          }
                        >
                          {evt.image && (
                            <div
                              className={`transition-all${
                                isActive(i, d) ? ' mr-1' : ''
                              } flex-none go-event-img-wrapper`}
                              style={{ width: isActive(i, d) ? '30%' : '0%' }}
                            >
                              <div className="rectangle relative border-1/2 overflow-clip go-event-img-container">
                                <LazyLoadImage
                                  className="full go-event-img"
                                  loading="lazy"
                                  src={evt.image}
                                  effect="blur"
                                />
                              </div>
                            </div>
                          )}
                          <div className="w-full grow overflow-x-hidden go-event-container">
                            <div className="flex">
                              {evt.attributes.map(attr => (
                                <span
                                  key={attr}
                                  className={`hidden text-xs whitespace-nowrap event-attribute event-attribute_${attr.replace(
                                    / /g,
                                    '_'
                                  )}`}
                                >
                                  <span className="px-1 rounded-full text-white bg-emphasis text-xs font-bold">
                                    {attr}
                                  </span>
                                </span>
                              ))}
                              {evt.highlights.map(attr => (
                                <span
                                  key={attr}
                                  className={`hidden text-xs whitespace-nowrap event-highlight event-highlight_${attr.replace(
                                    / /g,
                                    '_'
                                  )}`}
                                >
                                  <span className="px-1 rounded-full text-white bg-emphasis text-xs font-bold">
                                    {attr}
                                  </span>
                                </span>
                              ))}
                            </div>
                            <div
                              title={evt.name}
                              className="text-accent text-sm font-bold truncate go-event-name"
                            >
                              {evt.name}
                            </div>
                            <div
                              title={evt.venue}
                              className="italic text-sm truncate go-event-venue"
                            >
                              {evt.venue}
                            </div>
                            <div
                              title={evt.timeString.toString()}
                              className="text-sm truncate go-event-time"
                            >
                              {/* FIXME: Why is this an array sometimes? */}
                              {evt.timeString.toString()}
                            </div>
                          </div>
                        </div>
                      </Popover>
                    ))}
              </div>
            </div>
          </div>
        ))}
      </div>
      {isMobile && (
        <div className="grid gap-3 go-mobile-events">
          {filterEvents(events[focusCell - 1] || [], selectedAttribute).map((evt, i) => (
            <PopoverContent
              key={evt.id}
              event={evt}
              config={config}
              togglePopover={togglePopover}
              noClose
              imgClassName="-mx-3"
              className={`w-full p-3 border-top-1 border-bottom-1 border-table bg-background go-event-popover go-event-popover-${i}`}
            />
          ))}
          {filterEvents(events[focusCell - 1] || [], selectedAttribute).length < 1 && (
            <div className="p-3 border-top-1 border-table italic text-disabled text-center">
              {loading ? 'Loading...' : 'No content available'}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

interface PopoverContentPropsBase {
  event: ExportApiEvent;
  config: EmbedTemplateParams['config'];
  imgClassName?: string;
  noClose?: boolean;
  togglePopover: (
    eId: string | undefined,
    state: boolean,
    clickEvt: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
}

type PopoverContentProps = PopoverContentPropsBase & React.HTMLProps<HTMLDivElement>;

export function PopoverContent(props: PopoverContentProps) {
  const { event, config, className, togglePopover, noClose, imgClassName, ...rest } = props;

  const classes = ['relative', className];

  return (
    <div className={classes.join(' ')} {...rest}>
      {!noClose && (
        <div
          className="text-white bg-accent cursor-pointer event-close"
          onClick={e => togglePopover(event.id, false, e)}
        >
          <svg width="1rem" height="1rem" viewBox="0 0 24 24">
            <path
              d="M20.7457 3.32851C20.3552 2.93798 19.722 2.93798 19.3315 3.32851L12.0371 10.6229L4.74275 3.32851C4.35223 2.93798 3.71906 2.93798 3.32854 3.32851C2.93801 3.71903 2.93801 4.3522 3.32854 4.74272L10.6229 12.0371L3.32856 19.3314C2.93803 19.722 2.93803 20.3551 3.32856 20.7457C3.71908 21.1362 4.35225 21.1362 4.74277 20.7457L12.0371 13.4513L19.3315 20.7457C19.722 21.1362 20.3552 21.1362 20.7457 20.7457C21.1362 20.3551 21.1362 19.722 20.7457 19.3315L13.4513 12.0371L20.7457 4.74272C21.1362 4.3522 21.1362 3.71903 20.7457 3.32851Z"
              fill="#FFFFFF"
            />
          </svg>
        </div>
      )}
      {event.image && (
        <div className={[imgClassName || '', `-mt-3 mb-2 go-ex-evt-img-wrapper`].join(' ')}>
          <div className="rectangle relative border-1/2 overflow-clip go-ex-evt-img-container">
            <LazyLoadImage
              className="full go-event-img"
              loading="lazy"
              src={event.image}
              effect="blur"
            />
          </div>
        </div>
      )}
      <div className="w-full grow go-ex-event-container">
        <div className="flex flex-wrap">
          {event.attributes.map(attr => (
            <span
              key={attr}
              className={`hidden text-xs whitespace-nowrap event-attribute event-attribute_${attr.replace(
                / /g,
                '_'
              )}`}
            >
              <span className="px-1 rounded-full text-white bg-emphasis text-xs font-bold">
                {attr}
              </span>
            </span>
          ))}
          {event.highlights.map(attr => (
            <span
              key={attr}
              className={`hidden text-xs whitespace-nowrap event-highlight event-highlight_${attr.replace(
                / /g,
                '_'
              )}`}
            >
              <span className="px-1 rounded-full text-white bg-emphasis text-xs font-bold">
                {attr}
              </span>
            </span>
          ))}
        </div>
        <div className="text-accent text-sm font-bold go-ex-event-name">{event.name}</div>
        <div className="text-sm italic go-ex-event-venue">{event.venue}</div>
        <div className="text-sm go-ex-event-time">
          {/* FIXME: Why is this an array sometimes? */}
          {event.timeString.toString()}
        </div>
        {event.description && (
          <div className="text-sm mt-2 go-ex-event-description">{event.description}</div>
        )}
        {event.callToAction && (
          <div className="mt-2">
            <a
              className="text-sm mt-2 text-emphasis go-ex-event-cta"
              href={event.callToAction}
              target="_blank"
            >
              {config.ctaLabel}
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
