import React from 'react';
import ReactDOM from 'react-dom';
// @ts-ignore-next-line no types
import retargetEvents from 'react-shadow-dom-retarget-events';
import { EmbedTemplateParams } from '../..';

import App from './App';

function getStyle(config: EmbedTemplateParams['config']) {
  return `
    button {
      -webkit-appearance: none;
      border-radius: 0;
      text-align: inherit;
      background: none;
      box-shadow: none;
      padding: 0;
      cursor: pointer;
      color: inherit;
      font: inherit;
    }

    * {
      box-sizing: border-box;
      font-family: "Poppins", sans-serif;
      font-size: 1rem;
      line-height: 1.5rem;
      color: ${config.baseColor};
      border-width: 0;
      border-style: solid;
      border-color: ${config.baseColor};
    }

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      text-overflow: '';
      background-color: transparent;
    }

    .go-event:hover {
      background-color: ${config.highlightColor};
    }

    .go-button:hover {
      background-color: ${config.highlightColor};
    }

    .go-cell-container::-webkit-scrollbar {
      display: none;
    }

    .go-cell-container {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }

    img {
      object-fit: cover;
    }

    .underline {
      text-decoration: underline;
    }

    .rounded-full {
      border-radius: 9999px;
    }

    .event-close {
      position: absolute;
      right: 0;
      z-index: 2;
      width: 25px;
      height: 25px;
      top: -27px;
      border-radius: 999px;
      display: grid;
      align-items: center;
      justify-items: center;
    }

    .text-right {
      text-align: right;
    }

    .justify-end {
      justify-content: flex-end;
    }

    .whitespace-nowrap {
      white-space: nowrap;
    }

    .flex-wrap {
      flex-wrap: wrap;
    }

    .text-right {
      text-align: right;
    }

    .event-attribute,
    .event-highlight {
      padding: 0.1rem;
      display: ${config.showAttributes ? 'inline' : 'none'} !important;
    }

    .text-white {
      color: white;
    }

    .text-base {
      color: ${config.baseColor};
    }

    .text-secondary {
      color: ${config.secondaryColor};
    }

    .text-accent {
      color: ${config.accentColor};
    }

    .text-disabled {
      color: ${config.disabledColor};
    }

    .text-emphasis {
      color: ${config.emphasisColor};
    }

    .text-background {
      color: ${config.backgroundColor};
    }

    .text-highlight {
      color: ${config.highlightColor};
    }

    .text-table {
      color: ${config.tableColor};
    }

    .text-active {
      color: ${config.activeColor};
    }

    .bg-white {
      background-color: white;
    }

    .bg-black {
      background-color: black;
    }

    .bg-smoke {
      background-color: whitesmoke;
    }

    .bg-base {
      background-color: ${config.baseColor};
    }

    .bg-secondary {
      background-color: ${config.secondaryColor};
    }

    .bg-accent {
      background-color: ${config.accentColor};
    }

    .bg-disabled {
      background-color: ${config.disabledColor};
    }

    .bg-emphasis {
      background-color: ${config.emphasisColor};
    }

    .bg-background {
      background-color: ${config.backgroundColor};
    }

    .bg-highlight {
      background-color: ${config.highlightColor};
    }

    .bg-table {
      background-color: ${config.tableColor};
    }

    .bg-active {
      background-color: ${config.activeColor};
    }

    .border-white {
      border-color: white;
    }

    .border-base {
      border-color: ${config.baseColor};
    }

    .border-secondary {
      border-color: ${config.secondaryColor};
    }

    .border-accent {
      border-color: ${config.accentColor};
    }

    .border-disabled {
      border-color: ${config.disabledColor};
    }

    .border-emphasis {
      border-color: ${config.emphasisColor};
    }

    .border-background {
      border-color: ${config.backgroundColor};
    }

    .border-highlight {
      border-color: ${config.highlightColor};
    }

    .border-table {
      border-color: ${config.tableColor};
    }

    .border-active {
      border-color: ${config.activeColor};
    }

    .cursor-pointer {
      cursor: pointer;
    }

    .opacity-50 {
      opacity: 0.5;
    }

    .grid {
      display: grid;
    }

    .flex {
      display: flex;
    }

    .hidden {
      display: none;
    }

    .grow {
      flex-grow: 1;
    }

    .flex-none {
      flex: none
    }

    .text-center {
      text-align: center;
    }

    .grid-cols-7 {
      grid-template-columns: repeat(7, minmax(0, 1fr));
    }

    .border {
      border-width: 1px;
    }

    .border-1/2 {
      border-width: 0.5px;
    }

    .border-top-2 {
      border-top-width: 2px;
    }

    .border-bottom-1 {
      border-bottom-width: 1px;
    }

    .border-right-0 {
      border-right-width: 0;
    }

    .border-left-0 {
      border-left-width: 0;
    }

    .border-top-1 {
      border-top-width: 1px;
    }

    .border-left-2 {
      border-left-width: 2px;
    }

    .border-left-1 {
      border-left-width: 1px;
    }

    .pr-0 {
      padding-right: 0;
    }

    .pl-1 {
      padding-left: 0.25rem;
    }

    .transition-all {
      transition-property: all;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 150ms;
    }

    .transition-none {
      transition-property: none;
    }

    .truncate {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .relative {
      position: relative;
    }

    .items-center {
      align-items: center;
    }

    .items-start {
      align-items: start;
    }

    .full {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .overflow-x-hidden {
      overflow-x: hidden;
    }

    .sticky {
      position: sticky;
    }

    img.fullscreen {
      overflow: hidden;
    }

    .shadow {
      box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    }

    .shadow-lg {
      box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    }

    .rounded {
      border-radius: 0.25rem;
    }

    .rounded-right {
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }

    .rounded-left {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }

    .overflow-clip {
      overflow: clip;
    }

    .space-x-2 > * + * {
    	margin-left: 0.5rem;
    }

    .space-x-2:first-child {
      margin-left: 0;
    }

    .w-full {
      width: 100%;
    }

    .sticky {
      position: sticky;
    }

    .min-h-64 {
      min-height: 16rem;
    }

    .w-64 {
      width: 16rem;
    }

    .w-80 {
      width: 20rem;
    }

    .mr-1 {
      margin-right: 0.25rem;
    }

    .py-1 {
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
    }

    .py-2 {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }

    .py-3 {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
    }

    .mb-1 {
      margin-bottom: 0.25rem;
    }

    .p-1 {
      padding: 0.25rem;
    }

    .p-2 {
      padding: 0.5rem;
    }

    .p-3 {
      padding: 0.75rem;
    }

    .px-3 {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
    }

    .px-1 {
      padding-left: 0.25rem;
      padding-right: 0.25rem;
    }

    .pt-1 {
      padding-top: 0.25rem;
    }

    .px-2 {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }

    .pt-3 {
      padding-top: 0.75rem;
    }

    .pb-2 {
      padding-bottom: 0.5rem;
    }

    .pt-2 {
      padding-top: 0.5rem;
    }

    .m-2 {
      margin: 0.5rem;
    }

    .mr-2 {
      margin-right: 0.5rem;
    }

    .ml-2 {
      margin-left: 0.5rem;
    }

    .mb-2 {
      margin-bottom: 0.5rem;
    }

    .mt-2 {
      margin-top: 0.5rem;
    }

    .mt-3 {
      margin-top: 0.75rem;
    }

    .my-2 {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }

    .-mt-2 {
      margin-top: -0.5rem;
    }

    .-mt-3 {
      margin-top: -0.75rem;
    }

    .-mx-2 {
      margin-left: -0.5rem;
      margin-right: -0.5rem;
    }

    .-mx-3 {
      margin-left: -0.75rem;
      margin-right: -0.75rem;
    }

    .-mr-2 {
      margin-right: -0.5rem;
    }

    .mr-auto {
      margin-right: auto;
    }

    .min-w-0 {
      min-width: 0;
    }

    .ml-auto { 
      margin-left: auto;
    }

    .top-0 {
      top: 0;
    }

    .uppercase {
      text-transform: uppercase
    }

    .text-xs {
      font-size: 0.75rem;
      line-height: 1rem;
    }

    .text-sm {
      font-size: 0.875rem;
      line-height: 1.25rem;
    }

    .font-bold {
      font-weight: 700;
    }

    .italic {
      font-style: italic;
    }

    .gap-2 {
      gap: 0.5rem;
    }

    .gap-3 {
      gap: 0.75rem;
    }

    .square {
      padding-top: 100%;
    }

    .rectangle {
      padding-top: 56.25%;
    }
  `;
}

export default function render() {
  const shadowParent = document.getElementById('go-shadow');

  if (!shadowParent || !shadowParent.dataset.config) {
    console.error(`Missing shadow element or config`);
    return;
  }

  try {
    const config = JSON.parse(shadowParent.dataset.config);
    const shadowRoot = shadowParent.attachShadow({ mode: 'open' });

    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href =
      'https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,400;0,600;0,700;1,400;1,600&display=swap';
    link.crossOrigin = '';
    shadowRoot.appendChild(link);

    const stylesDiv = document.createElement('style');
    stylesDiv.innerHTML = getStyle(config);
    shadowRoot.appendChild(stylesDiv);

    if (config.additionalCss) {
      const userStylesDiv = document.createElement('style');
      userStylesDiv.innerHTML = config.additionalCss;
      shadowRoot.appendChild(userStylesDiv);
    }

    const appDiv = document.createElement('div');
    shadowRoot.appendChild(appDiv);

    ReactDOM.render(<App config={config} />, appDiv);
    // workaround required because events in react dont work by default on shadow dom
    retargetEvents(appDiv);
  } catch (err) {
    console.error(err);
  }
}
